
import * as Li9za2lrby5tanM from './skiko.mjs';
import * as _ref_eW91dHViZS1wbGF5ZXI_ from 'youtube-player';
import { instantiate } from './deck.bnorm.dev-deck.bnorm.dev-wasm-js.uninstantiated.mjs';


const exports = (await instantiate({
    './skiko.mjs': Li9za2lrby5tanM,
    'youtube-player': _ref_eW91dHViZS1wbGF5ZXI_
})).exports;

export const {
main,
memory,
_initialize
} = exports


